import axios from 'axios';

const parseCSV = function(csvData: string) {
  const allResults = [];
  const lines = csvData.split('\n');
  const headerFields = lines[0].split(';').map((f) => f.trim());
  for (let i = 1; i < lines.length; i++) {
    const lineFields = lines[i].split(';');
    if( lineFields.length <= 2 ) continue;
    const result: {
      [Key: string]: string;
    } = {};
    for (let j = 0; j < lineFields.length; j++) {
      result[headerFields[j]] = lineFields[j].trim();
    }
    allResults.push(result);
  }
  console.log(allResults);
  return allResults;
};

export default class SanitasService {
  getStatusECs() {
    return axios
      .get('https://hoses.sanitas-water.nl/db/ec')
      .then((res) => parseCSV(res.data))
      .catch((error) => console.log(error));
  }

  getStatusHoses() {
    return axios
      .get('https://hoses.sanitas-water.nl/db/tempHose')
      .then((res) => parseCSV(res.data))
      .catch((error) => console.log(error));
  }
}
